<template>
  <div>
    <div class="checkWrapper">
      <b-icon icon="check" class="checkIcon" />
    </div>
    <h1>Zahlung erhalten</h1>
    <p>Wir haben deine Zahlung erhalten</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .checkWrapper {
    left: calc(50% - 2rem);
    position: absolute;
    top: -2rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #6fdd4d;
    padding: 1rem;
  }
  .checkIcon {
    width: 2rem;
    height: 2rem;
    color: white;
  }
</style>